import React, { useState, useMemo, useEffect } from "react";
import { uuids, variants } from "../../../helpers/sayDuckProductUuid";

const Image = props => {
  const {
    getMaterialUuids,
    productId,
    canvasLoaded,
    resetSayduckInstance
  } = props;
  // eslint-disable-next-line  no-unused-vars
  const [_, setMaterialids] = useState({});
  const [sayduckInstance, setSayduckInstance] = useState(null);
  const [loading, setLoading] = useState(false);
  const addScript = () => {
    const script = document.createElement("script");
    script.setAttribute("id", "sayduckId");
    script.src = "https://viewer.sayduck.com";
    script.defer = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    addScript();
    window.addEventListener("sayduck.api-ready", event => {
      try {
        const api = event.detail.instance;
        const materialUUIDs = api.scene.getAllMaterialUuids();
        setSayduckInstance(api.scene);
        setMaterialids(materialUUIDs);
        getMaterialUuids(materialUUIDs);
      } catch (e) {
        // No need to perform any actions
      }
    });
    return () => {
      window.removeEventListener("sayduck.api-ready", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reinitiateScript = () => {
      const script = document.getElementById("sayduckId");
      script.remove();
      addScript();
    };
    if (productId) {
      const sayduckDiv = document.getElementsByTagName("sayduck-viewer")[0];
      if (sayduckDiv) {
        sayduckDiv.setAttribute("product", uuids[productId]);

        const sayduckShadowRoot = sayduckDiv.shadowRoot;
        if (sayduckShadowRoot) {
          var imageCanvas = sayduckShadowRoot.querySelector("canvas");
          resetSayduckInstance();
          if (imageCanvas?.length !== 0) {
            if (sayduckInstance) {
              setLoading(true);
              setMaterialids({});
              getMaterialUuids({});
              setTimeout(() => {
                setLoading(false);
                reinitiateScript();
              }, 500);
            }
          } else {
            reinitiateScript();
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const canvasState = useMemo(() => canvasLoaded, [canvasLoaded]);

  const returnDataViewerOptions = useMemo(() => {
    let options = {
      mode: "variants",
      background: "transparent",
      "hide-loading": true,
      "hide-product-info": true,
      "hide-photo-studio": true,
      "hide-embed": true,
      "hide-picker": true,
      "hide-annotations": true,
      "hide-web-ar": true,
      "hide-action-menu": true,
      "hide-branding": true
    };
    if (productId) {
      if (variants[productId]) {
        options = {
          ...options,
          variant: variants[productId]
        };
      }
    }
    return options;
  }, [productId]);

  return (
    <div
      className="sayduck-wrapper"
      style={{
        minWidth: "300px",
        width: "100%",
        height: "300px",
        padding: "0 25px"
      }}
    >
      {!loading && (
        <sayduck-viewer
          product={uuids[productId]}
          {...returnDataViewerOptions}
        ></sayduck-viewer>
      )}
      {(!canvasState || !sayduckInstance || loading) && (
        <img
          className="product__image--loader"
          src="/img/loader.gif"
          alt="loading..."
        />
      )}
    </div>
  );
};

export default Image;
