export const productData = [
  // {
  //   id: "S155C",
  //   name: "Jumbo Square",
  //   status: {
  //     code: 1,
  //     message: 'Ships within 6 weeks',
  //   },
  //   dimension: "13 x 13",
  //   price: {
  //     us: "2,399",
  //     ca: "2,499",
  //     au: "2,399",
  //   },
  //   image: "/img/products/S155-size.svg",
  //   data: [
  //     {
  //       label: "Legs",
  //       id: "L",
  //       text: "There are 8 legs and you can choose from 7 colour options.",
  //       unavailableColor: ['red', 'blue'],
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "4 colours alternating arrangement",
  //               id: "AL4A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "Random colour arrangement",
  //               id: "RA00",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Frame",
  //       id: "F",
  //       text:
  //         "There are 8 parts of the frame and you can choose from 7 colour options.",
  //       unavailableColor: ['pink', 'red'],
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Mat Rods",
  //       id: "M",
  //       text: "There are 76 mat rods and 7 colour options",
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "19 colours alternating arrangement",
  //               id: "AL19",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "19 colours alternating arrangement",
  //               id: "AL19",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Enclosure Rods",
  //       id: "E",
  //       text: "There are 12 enclosure rods and 7 colour options",
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "3 colours",
  //           id: "3",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "4 colours alternating arrangement",
  //               id: "AL4A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "3 colours alternating arrangement",
  //               id: "AL3A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "6 colours",
  //           id: "6",
  //           options: [
  //             {
  //               label: "1 colour random arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours random arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: "S113C",
  //   name: "Large Square",
  //   status: {
  //     code: 1,
  //     message: 'Ships within 8-10 weeks',
  //   },
  //   dimension: "11 x 11",
  //   price: {
  //     us: "1,999",
  //     ca: "2,199",
  //     au: "1,999",
  //   },
  //   image: "/img/products/S113-size.svg",
  //   data: [
  //     {
  //       label: "Legs",
  //       id: "L",
  //       text: "There are 8 legs and you can choose from 7 colour options.",
  //       unavailableColor: ['pink'],
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "4 colours alternating arrangement",
  //               id: "AL4A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "Random colour arrangement",
  //               id: "RA00",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Frame",
  //       id: "F",
  //       text:
  //         "There are 8 parts of the frame and you can choose from 7 colour options.",
  //       unavailableColor: ['pink'],
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Mat Rods",
  //       id: "M",
  //       text: "There are 64 mat rods and 7 colour options",
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "4 colours alternating arrangement",
  //               id: "AL4A",
  //             },
  //             {
  //               label: "8 colours alternating arrangement",
  //               id: "AL8A",
  //             },
  //             {
  //               label: "16 colours alternating arrangement",
  //               id: "AL16",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "4 colours alternating arrangement",
  //               id: "AL4A",
  //             },
  //             {
  //               label: "8 colours alternating arrangement",
  //               id: "AL8A",
  //             },
  //             {
  //               label: "16 colours alternating arrangement",
  //               id: "AL16",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Enclosure Rods",
  //       id: "E",
  //       text: "There are 12 enclosure rods and 7 colour options",
  //       config: [
  //         {
  //           label: "1 colour",
  //           id: "1",
  //           options: [],
  //         },
  //         {
  //           label: "2 colours",
  //           id: "2",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "Half half",
  //               id: "HH00",
  //             },
  //           ],
  //         },
  //         {
  //           label: "3 colours",
  //           id: "3",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours alternating arrangement",
  //               id: "AL2A",
  //             },
  //             {
  //               label: "4 colours alternating arrangement",
  //               id: "AL4A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "4 colours",
  //           id: "4",
  //           options: [
  //             {
  //               label: "1 colour alternating arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "3 colours alternating arrangement",
  //               id: "AL3A",
  //             },
  //           ],
  //         },
  //         {
  //           label: "6 colours",
  //           id: "6",
  //           options: [
  //             {
  //               label: "1 colour random arrangement",
  //               id: "AL1A",
  //             },
  //             {
  //               label: "2 colours random arrangement",
  //               id: "AL2A",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    id: "O92C",
    name: "Large Oval",
    status: {
      code: 1,
      message: 'Ships within 12-14 weeks',
    },
    dimension: "8 x 13",
    price: {
      us: "1,799",
      ca: "1,999",
      au: "2,299"
    },
    image: "/img/products/O92-size.svg",
    data: [
      {
        label: "Legs",
        id: "L",
        text: "There are 6 legs and you can choose from 7 colour options.",
        unavailableColor: ['red', 'pink'],
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colours",
            id: "3",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colours",
            id: "6",
            options: [
              {
                label: "Random colour arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Frame",
        id: "F",
        text:
          "There are 6 parts of the frame and you can choose from 7 colour options.",
        unavailableColor: ['pink', 'red'],
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colours",
            id: "3",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colours",
            id: "6",
            options: [
              {
                label: "Random colour arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Mat Rods",
        id: "M",
        text: "There are 58 mat rods and 7 colour options",
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
        ],
      },
      {
        label: "Enclosure Rods",
        id: "E",
        text: "There are 10 enclosure rods and 7 colour options",
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "5 colours",
            id: "5",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "O77C",
    name: "Medium Oval",
    dimension: "8 x 11",
    price: {
      us: "1,499",
      ca: "1,749",
      au: "1,999",
    },
    image: "/img/products/O77-size.svg",
    status: {
      code: 1,
      message: 'Ships within 12-14 weeks',
    },
    data: [
      {
        label: "Legs",
        id: "L",
        text: "There are 6 legs and you can choose from 7 colour options.",
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colours",
            id: "3",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colours",
            id: "6",
            options: [
              {
                label: "Random colour arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Frame",
        id: "F",
        text:
          "There are 6 parts of the frame and you can choose from 7 colour options.",
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "3 colours",
            id: "3",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
            ],
          },
          {
            label: "6 colours",
            id: "6",
            options: [
              {
                label: "Random colour arrangement",
                id: "RA00",
              },
            ],
          },
        ],
      },
      {
        label: "Mat Rods",
        id: "M",
        text: "There are 52 mat rods and 7 colour options",
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
              {
                label: "13 colours alternating arrangement",
                id: "AL13",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "4 colours",
            id: "4",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "13 colours alternating arrangement",
                id: "AL13",
              },
            ],
          },
        ],
      },
      {
        label: "Enclosure Rods",
        id: "E",
        text: "There are 10 enclosure rods and 7 colour options",
        config: [
          {
            label: "1 colour",
            id: "1",
            options: [],
          },
          {
            label: "2 colours",
            id: "2",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "Half half",
                id: "HH00",
              },
            ],
          },
          {
            label: "5 colours",
            id: "5",
            options: [
              {
                label: "1 colour alternating arrangement",
                id: "AL1A",
              },
              {
                label: "2 colours alternating arrangement",
                id: "AL2A",
              },
            ],
          },
        ],
      },
    ],
  },
  // ! keep the code, might be needed in future
  // {
  //   id: "R132",
  //   name: "Jumbo Round Smart",
  //   dimension: "13 x 13",
  //   price: "",
  //   image: "/img/products/R132-size.svg",
  // },
  // {
  //   id: "R79",
  //   name: "Medium Round",
  //   dimension: "10 x 10",
  //   price: "",
  //   image: "/img/products/R79-size.svg",
  // },
  // {
  //   id: "R54",
  //   name: "Compact Round",
  //   dimension: "8 x 8",
  //   price: "",
  //   image: "/img/products/R54-size.svg",
  // },
];
