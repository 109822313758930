/* eslint-disable no-loop-func */
import Axios from 'axios';

const getData = async () => {
  let domain = window.location.protocol + '//' + window.location.host;
  return await Axios.get(
    domain+'/api/inventoryStatus'
    // 'http://localhost:3000/json/data.json'
  )
    .then((res) => {
      return res.data.data.inventory;
    })
    .catch((err) => {
      return 'Sorry, looks like there was a problem with the connection. Please refresh and try again or contact Customer Care at +1800 586 772';
    });
};

/**
 * Add the inventory satus to the object
 * @param {object} target trampoline or accessories object that needs modification
 * @param {array} inventory list of inventory status from the api
 */
const findAndAdd = (target, inventory) => {
  const trampolineInventory = inventory.find((tram) => tram.id === target.id);

  if (trampolineInventory?.status) {

    target["sku"] = trampolineInventory['sku'];
  }

  return {
    ...target,
    status: trampolineInventory?.status || target.status,
  };
};

const findAndAddInventory = (target, inventory) => {
  const trampolineInventory = inventory.find((tram) => tram.id === target.id);

  if (trampolineInventory?.status) {

    target["parts"] = trampolineInventory['parts'];
    target["status"] = trampolineInventory['status'];
    target["nametag"] = true;

    if(trampolineInventory['nametag'] !== undefined){
      target["nametag"] = trampolineInventory['nametag'];
    }

    //FOR ACCESSORIES STATUS
    target["accessories"] = [];

    for (let i = 0; i < trampolineInventory['accessories'].length; i++) {
      let statusIndex = inventory.findIndex(p => p.sku === trampolineInventory['accessories'][i]);

      if (statusIndex >= 0 && inventory[statusIndex]['status'].code.toString() !== "4") {
        target["accessories"].push({
          "sku": trampolineInventory['accessories'][i],
          "status": inventory[statusIndex]['status']
        });
      }
    }

    //UNAVAILABLE COLORS SETUP

    //FOR LEGS
    if (target['parts']['parts_legs']) {
      var unavailableLegs = [], hiddenLegs = [];

      var allLegs = target['parts']['parts_legs'];

      for (let i = 0; i < allLegs.length; i++) {
        for (let j = 0; j < allLegs[i]['list'].length; j++) {
          let foundIndex = inventory.findIndex(partLeg => partLeg.sku === allLegs[i]['list'][j] && partLeg.color === allLegs[i]['color'])
          if (foundIndex >= 0) {
            if (inventory[foundIndex]['status']['code'] === 4) {
              hiddenLegs.push(allLegs[i]['color']);
              break;
            }
            else if (inventory[foundIndex]['status']['code'] === 2) {
              unavailableLegs.push(allLegs[i]['color']);
              break;
            }
          }
          else {
            unavailableLegs.push(allLegs[i]['color']);
            break;
          }
        }
      }

      target['parts']['parts_legs']['unavailableColor'] = unavailableLegs;
      target['parts']['parts_legs']['hiddenColor'] = hiddenLegs;
    }

    //FOR FRAMES
    if (target['parts']['parts_frames']) {
      var unavailableFrames = [], hiddenFrames = [];

      var allFrames = target['parts']['parts_frames'];

      for (let i = 0; i < allFrames.length; i++) {
        for (let j = 0; j < allFrames[i]['list'].length; j++) {
          let foundIndex = inventory.findIndex(partFrame => partFrame.sku === allFrames[i]['list'][j] && partFrame.color === allFrames[i]['color'])
          if (foundIndex >= 0) {
            if (inventory[foundIndex]['status']['code'] === 4) {
              hiddenFrames.push(allFrames[i]['color']);
              break;
            }
            else if (inventory[foundIndex]['status']['code'] === 2) {
              unavailableFrames.push(allFrames[i]['color']);
              break;
            }
          }
          else {
            unavailableFrames.push(allFrames[i]['color']);
            break;
          }
        }
      }

      target['parts']['parts_frames']['unavailableColor'] = unavailableFrames;
      target['parts']['parts_frames']['hiddenColor'] = hiddenFrames;
    }

    //FOR MATRODS
    if (target['parts']['parts_matrods']) {
      var unavailableMatRods = [], hiddenMatrods = [];

      var allMatRods = target['parts']['parts_matrods'];

      for (let i = 0; i < allMatRods.length; i++) {
        for (let j = 0; j < allMatRods[i]['list'].length; j++) {
          let foundIndex = inventory.findIndex(partMatrod => partMatrod.sku === allMatRods[i]['list'][j] && partMatrod.color === allMatRods[i]['color'])
          if (foundIndex >= 0) {
            if (inventory[foundIndex]['status']['code'] === 4) {
              hiddenMatrods.push(allMatRods[i]['color']);
              break;
            }
            else if (inventory[foundIndex]['status']['code'] === 2) {
              unavailableMatRods.push(allMatRods[i]['color']);
              break;
            }
          }
          else {
            unavailableMatRods.push(allMatRods[i]['color']);
            break;
          }
        }
      }

      target['parts']['parts_matrods']['unavailableColor'] = unavailableMatRods;
      target['parts']['parts_matrods']['hiddenColor'] = hiddenMatrods;
    }

    //FOR ENCLOSURE RODS
    if (target['parts']['parts_enclosure']) {
      var unavailableEnclosures = [], hiddenEnclosures = [];

      var allEnclosures = target['parts']['parts_enclosure'];

      for (let i = 0; i < allEnclosures.length; i++) {
        for (let j = 0; j < allEnclosures[i]['list'].length; j++) {
          let foundIndex = inventory.findIndex(partEnclosure => partEnclosure.sku === allEnclosures[i]['list'][j] && partEnclosure.color === allEnclosures[i]['color'])
          if (foundIndex >= 0) {
            if (inventory[foundIndex]['status']['code'] === 4) {
              hiddenEnclosures.push(allEnclosures[i]['color']);
              break;
            }
            else if (inventory[foundIndex]['status']['code'] === 2) {
              unavailableEnclosures.push(allEnclosures[i]['color']);
              break;
            }
          }
          else {
            unavailableEnclosures.push(allEnclosures[i]['color']);
            break;
          }
        }
      }

      target['parts']['parts_enclosure']['unavailableColor'] = unavailableEnclosures;
      target['parts']['parts_enclosure']['hiddenColor'] = hiddenEnclosures;
    }
  }

  return {
    ...target,
    status: trampolineInventory?.status || target.status,
  };
};

export const addInventoryStatus = async (productInfo, accessoriesInfo) => {
  // make api call
  const inventory = await getData();

  const updatedProducts = productInfo.map((product) => {
    const value = findAndAddInventory(product, inventory);
    return value;
  });

  const udpatedAccessories = accessoriesInfo.map((accessory) => {
    const value = findAndAdd(accessory, inventory);
    return value;
  });

  return {
    product: updatedProducts,
    accessory: udpatedAccessories,
  };
};
