import React, { Component } from 'react';
import posed from 'react-pose';
import { plaqueData } from './data';
import './plaque.style.css';
import { fontData } from './fontData';
import ScrollView from '../../../ScrollView';
import { sliderSettings } from '../../../ScrollView/sliderSettings';
import PlaqueLogo from './PlaqueLogo';

const Box = posed.div({
  hidden: {
    height: 0,
    scale: 0.7,
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: '200px',
  },
  visible: {
    height: '100%',
    scale: 1,
    backgroundColor: 'rgba(255,255,255, 0.95)',
    borderRadius: '0px',
    transition: {
      default: { ease: 'easeIn', duration: 300 },
    },
  },
});

export default class Plaque extends Component {
  constructor() {
    super();
    this.state = {
      plaques: plaqueData,
      active: null,
      customize: false,
      inputText: '',
      lines: 1,
      size: 14,
      lineOneLength: 0,
      lineTwoLength: 0,
      lineOne: '',
      lineTwo: '',
      fonts: fontData,
      activeFont: 'Meatball',
      click: true,
      activeIndex: 0,
      isCustom: false,
      plaqueColor: 'ffffff',
    };

    this.updateFont = this.updateFont.bind(this);
    this.updateText = this.updateText.bind(this);
  }

  componentDidMount() {
    const { selectedTheme, fontDetail } = this.props;
    let editText = fontDetail.lineOne;
    // if there is second line in the plaque
    if (fontDetail.lineTwo && fontDetail.lineTwo.length > 0) {
      editText += '\n' + fontDetail.lineTwo;
    }

    /**
     * When a theme is selected
     */
    if (selectedTheme) {
      const { fontDetail } = selectedTheme;
      const pickedFont = fontData.find(fon => fon.urlLabel === fontDetail.activeFont);
      this.setState({
        inputText: editText,
        lines: 1,
        size: fontDetail.fontSize,
        lineOneLength: fontDetail.lineOne.length,
        lineTwoLength: fontDetail.lineTwo.length,
        lineOne: fontDetail.lineOne,
        lineTwo: fontDetail.lineTwo,
        activeFont: pickedFont.value,
        plaqueColor: fontDetail.plaqueColor,
        isCustom: true,
      });
    }

    /**
     * When manual customization is selected
     */
    if (fontDetail.isCustom) {
      const pickedFont = fontData.find(fon => fon.urlLabel === fontDetail.activeFont);
      const activePlque = plaqueData.find(pl => pl.color === fontDetail.color);

      this.setState({
        active: activePlque,
        inputText: editText,
        lines: 1,
        size: fontDetail.fontSize,
        lineOneLength: fontDetail.lineOne.length,
        lineTwoLength: fontDetail.lineTwo.length,
        lineOne: fontDetail.lineOne,
        lineTwo: fontDetail.lineTwo,
        activeFont: pickedFont.value,
        plaqueColor: fontDetail.plaqueColor,
        isCustom: true,
      });
    }
  }

  /**
   * Update the text of the plaque
   * and font size based on the text and font family
   */
  updateText(event) {
    // let { lineOneLength, lineTwoLength, lines, lineTwo, lineOne } = this.state;
    let { lines, lineTwo, lineOne } = this.state;
    const val = event.target.value;
    // Check if there are 2 returns
    var letters = /^[0-9a-zA-Z \n'".,*‘’“”]+$/;
    if (val.trim() !== '' && !val.match(letters)) {
      return;
    }
    const numOfReturns = val.split('\n');

    // if enter is removed from the second line
    if (
      lineOne.length > 1 &&
      lineTwo &&
      lineOne.length + lineTwo.length > 14 &&
      numOfReturns.length < 2
    ) {
      return;
    }

    if (numOfReturns.length > 2) {
      return;
    }

    if (numOfReturns.length <= 2) {
      lineOne = numOfReturns[0];
      lineTwo = numOfReturns[1] || '';
    }

    // check if the value needs to be reset of not
    if (val.trim() === '') {
      this.setState({
        inputText: val,
        size: 20,
        lines: 1,
        lineOneLength: 0,
        lineTwoLength: 0,
        lineOne: '',
        lineTwo: '',
      });
      return false;
    }

    // line two has value
    if (lineOne.length === 0 && lineTwo.length > 0) {
      return false;
    }

    /**
     * Keep track of line one length and update font based on that
     * when the enter is pressed, update the state to line 2
     * when line 2 is active check which line length is bigger, 1 || 2
     * which ever is bigger the font size will be based on that
     *
     */

    // if the user is hitting enter on the second line
    if (lineTwo && lineTwo.length > 13) {
      if ((lines === 2 && val.endsWith('\n')) || val.length > 29) {
        return false;
      }
    }

    // Keep track of the font size
    const size = () => {
      if ((lineTwo && lineTwo.length > 0) || (val.length > 11 && val.length < 15)) {
        if (this.state.activeFont === 'classic-comic') {
          return 12;
        }

        if (this.state.activeFont === 'Meatball') {
          return 12;
        }

        return 15;
      }

      if (val.length > 5 && val.length < 8) {
        if (this.state.activeFont === 'classic-comic') {
          return 18;
        }

        if (this.state.activeFont === 'Meatball') {
          return 18;
        }

        return 20;
      }

      if (val.length > 7) {
        // font family is classic comic, make the size smaller
        // if (this.state.activeFont === 'classic-comic') {
        //   return 18 / Math.round(lineOne.length * 0.192);
        // }
        // if (this.state.activeFont === 'Meatball') {
        //   return 18 / Math.round(lineOne.length * 0.1834);
        // }
        // if (this.state.activeFont === 'SignPainter') {
        //   return 18 / Math.round(lineOne.length * 0.1356);
        // }
        return 18 / Math.round(lineOne.length * 0.1156);
      }

      return 30;
    };

    // if you press enter for the first time
    if (val.endsWith('\n')) {
      this.setState(prevState => {
        return {
          inputText: `${val}`,
          lines: 2,
          size: size(),
          lineTwoLength: prevState.lineTwoLength + 1,
        };
      });
    }

    // two lines merged
    if (
      lineOne &&
      lineTwo &&
      lineOne.length + lineTwo.length < 14 &&
      lineTwo.length === 0 &&
      lines === 2
    ) {
      this.setState({
        inputText: val,
        lineOne: lineOne,
        lineTwo: '',
        size: size(),
        lineOneLength: lineOne.length,
        lineTwoLength: 0,
        lines: 1,
      });
      return;
    }

    // backspace on the second line
    if (lineOne.length === 14 && lineTwo.length === 0 && lines === 2) {
      this.setState({
        inputText: val,
        lineOne: val,
        lines: 1,
        size: size(),
        lineOneLength: lineOne.length,
        lineTwo: lineTwo,
      });
    }

    // if the first line reaches the limit of 13 characters
    if (lineOne.length === 14 && lines === 1) {
      this.setState(prevState => ({
        inputText: `${val}\n`,
        lineOne: lineOne,
        lines: 2,
        size: size(),
        lineOneLength: prevState.lineOneLength + 1,
      }));
    } else if (lines === 2) {
      /**
       * the user is typing on the next line
       * split the input value after enter
       * the second value will be the second line value
       */
      const lineTwoVal = val.split('\n');
      this.setState(prevState => {
        if (lineTwoVal[1] && lineTwoVal[1].length === 15) {
          return;
        }
        return {
          inputText: val,
          lineOne: lineOne,
          lineTwo: lineTwo,
          size: size(),
          lineTwoLength: prevState.lineTwoLength + 1,
        };
      });
    } else if (lines === 1 && val.length < 14) {
      // default state
      this.setState(prevState => {
        return {
          inputText: val,
          lineOne: lineOne,
          size: this.state.lines === 1 ? size() : this.state.size,
          lineOneLength: prevState.lineOneLength + 1,
        };
      });
    }
  }

  /**
   * @param {object} event - get the value of the selected font family
   */
  updateFont(event) {
    const fontValue = event.target.value;

    this.setState({
      activeFont: fontValue,
    });
  }

  /**
   * Save the state of the selected plaque
   * send data to parent state to render trampoline image
   */
  updatePlaqueStyle() {
    const { updateFont, location } = this.props;

    const selectedFont = fontData.filter(font => font.value === this.state.activeFont);
    let activePlaqueColor = this.state.active.color;
    if (location === 'ca') {
      activePlaqueColor = 'ffffff';
    }
    // send custom plaque details to the main data store
    updateFont({
      activeFont: selectedFont[0].urlLabel,
      fontText: this.state.inputText,
      fontSize: this.state.size,
      lineOne: this.state.lineOne,
      lineTwo: this.state.lineTwo ? this.state.lineTwo : '',
      color: this.state.active.color,
      isCustom: true,
      plaqueColor: activePlaqueColor,
    });
    this.setState({
      customize: false,
      isCustom: true,
    });
  }

  /**
   * update theme font
   */
  updateThemeFontDetail() {
    const { updateThemeFont } = this.props;
    const selectedFont = fontData.filter(font => font.value === this.state.activeFont);
    const activePlaqueColor = this.state.plaqueColor || this.state.active.color;

    updateThemeFont({
      activeFont: selectedFont[0].urlLabel,
      fontText: this.state.inputText,
      fontSize: this.state.size,
      lineOne: this.state.lineOne,
      lineTwo: this.state.lineTwo,
      plaqueColor: activePlaqueColor,
    });

    this.setState({
      customize: false,
      isCustom: true,
    });
  }

  /**
   * Select the plaque
   * and set value in the active state
   */
  selectPlaque(plaqueItem) {
    /**
     * keep state of the defaut value
     * and reset the value in case of cencel and blank
     */
    const state = this.state;
    const defaultState = { ...state };

    // don't update state while dragging the slider
    if (!this.state.click) {
      return false;
    }

    // update the selected item
    this.setState({
      active: plaqueItem,
      customize: true,
      defaultState,
    });
  }

  /**
   * Select the default plaque and reset values
   */
  setDefault() {
    const { updateFont } = this.props;
    // const selectedFont = fontData.filter(font => font.value === this.state.activeFont);
    // const activePlaqueColor = this.state.plaqueColor || this.state.active.color;
    this.setState({
      active: null,
      inputText: '',
      lines: 1,
      size: 14,
      lineOneLength: 0,
      lineTwoLength: 0,
      lineOne: '',
      lineTwo: '',
      activeFont: 'Meatball',
      activeIndex: 0,
      isCustom: false,
      plaqueColor: 'ffffff',
    });

    updateFont({
      activeFont: 'Meatball',
      fontText: '',
      fontSize: 14,
      lineOne: '',
      lineTwo: '',
      isCustom: false,
      plaqueColor: 'ffffff',
    });
  }

  /**
   * Render text in the view
   * based on the text configuration selected by the user
   */
  renderText(plaque) {
    const { lineOne, lineTwo } = this.state;
    let customTextValue = plaque.name;
    /**
     * If the text is multi line
     * add a line break in the text
     * and concat the second line
     */
    if (lineOne.length > 0) {
      customTextValue = [<span>{lineOne}</span>];
    }
    if (lineTwo && lineTwo.length > 0) {
      customTextValue.push(<span>{lineTwo}</span>);
    }

    return <h3 className="plaque__item--heading">{customTextValue || plaque.name}</h3>;
  }

  render() {
    // const { active, customize, inputText, lines, lineOne, lineTwo } = this.state;
    const { active, customize, inputText, lines } = this.state;
    const { selectedTheme, location } = this.props;

    if (selectedTheme) {
      const { fontDetail } = selectedTheme;
      const { lineOne, lineTwo } = fontDetail;
      let customTextValue = '';
      /**
       * If the text is multi line
       * add a line break in the text
       * and concat the second line
       */
      if (lineOne.length > 0) {
        customTextValue = [<span>{lineOne}</span>];
      }
      if (lineTwo && lineTwo.length > 0) {
        customTextValue.push(<span>{lineTwo}</span>);
      }

      const defaultfont = () => {
        const active = fontData.find(font => font.urlLabel === fontDetail.activeFont);
        return active.value;
      };

      return (
        <div className="plaque__theme">
          <div className="scroll-view__header">
            <h3 className="scroll-view__heading">Give your trampoline a special name!</h3>
          </div>
          <p style={{ 'padding-left': '25px' }}></p>
          <div
            onClick={() => this.selectPlaque(fontDetail)}
            className="plaque__item"
            key={selectedTheme.id}
            style={{
              borderColor: `#${fontDetail.plaqueColor}`,
              color: `#${fontDetail.color}`,
              fontSize: fontDetail.fontSize * 1,
              fontFamily: defaultfont(),
              marginLeft: '1rem',
            }}
          >
            <h3 className="plaque__item--heading">{customTextValue}</h3>
            <PlaqueLogo color={fontDetail.plaqueColor} />
          </div>
          <Box className="plaque__animation" pose={customize ? 'visible' : 'hidden'}>
            {customize && (
              <div className="plaque__customize">
                <p>
                  <select value={this.state.activeFont} onChange={this.updateFont}>
                    {this.state.fonts.map((font, index) => {
                      return <option value={font.value} key={index} >{font.name}</option>;
                    })}
                  </select>
                </p>
                <div
                  className="plaque__item"
                  style={{
                    color: `#${active.color}`,
                    borderColor: `#${active.color}`,
                  }}
                >
                  <textarea
                    className="plaque__input"
                    placeholder="Text"
                    onChange={this.updateText}
                    value={inputText}
                    style={{
                      color: `#${active.color}`,
                      // fontSize: `${this.state.size * 1.6}px`,
                      fontFamily: this.state.activeFont,
                    }}
                    rows={lines}
                  />
                  <PlaqueLogo color={fontDetail.plaqueColor} />
                </div>

                <div className="plaque__help">
                  <em>You can add 2 lines, each up to 14 characters.</em>
                  <p>
                    <strong>NOTE: </strong>Only ' " , . * are allowed. No special characters or
                    symbols are supported.
                  </p>
                </div>

                <div className="plaque__actions">
                  <span
                    className="btn btn--border"
                    onClick={() => {
                      const { defaultState } = this.state;
                      this.setState({
                        ...defaultState,
                      });
                    }}
                  >
                    Cancel
                  </span>
                  <button
                    disabled={this.state.inputText.trim() === ''}
                    className="btn btn--primary"
                    onClick={() => this.updateThemeFontDetail()}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </Box>
        </div>
      );
    }

    return (
      <div className="plaque__wrapper">
        <ScrollView
          heading="Give your trampoline a special name!"
          settings={sliderSettings(this)}
          activeIndex={this.state.activeIndex}
        >
          {this.state.plaques.map((plaque, index) => {
            let plaqueItemClass = 'plaque__item';
            if (active && active.name === plaque.name) {
              plaqueItemClass += ' active';
            }
            if (active && active.name !== plaque.name) {
              plaqueItemClass += ' inactive';
            }

            let plaqueColor = plaque.color;
            if (location === 'ca') {
              plaqueColor = 'ffffff';
              plaque.plaqueColor = 'ffffff';
            }

            return (
              <div key={index}>
                <div
                  onClick={() => {
                    if (index === 0 && this.state.active !== null) {
                      this.setDefault();
                    }
                    if (index !== 0) {
                      this.selectPlaque(plaque);
                    }
                  }}
                  className={plaqueItemClass}
                  key={plaque.name}
                  style={{
                    borderColor: `#${plaque.color}`,
                    color: `#${plaque.color}`,
                    fontSize: `${this.state.size * 1.1}px`,
                    fontFamily: this.state.activeFont,
                  }}
                >
                  {index === 0 && <h3 className="plaque__item--heading">Default</h3>}

                  {index !== 0 && this.renderText(plaque)}
                  <PlaqueLogo color={plaqueColor} />
                </div>
              </div>
            );
          })}
        </ScrollView>

        <Box className="plaque__animation" pose={customize ? 'visible' : 'hidden'}>
          {customize && (
            <div className="plaque__customize">
              <p>
                <select value={this.state.activeFont} onChange={this.updateFont}>
                  {this.state.fonts.map((font, index) => {
                    return <option value={font.value} key={index}>{font.name}</option>;
                  })}
                </select>
              </p>
              <div
                className="plaque__item"
                style={{
                  color: `#${active.color}`,
                  borderColor: `#${active.color}`,
                }}
              >
                <textarea
                  className="plaque__input"
                  placeholder="Text"
                  onChange={this.updateText}
                  value={this.state.inputText}
                  style={{
                    color: `#${active.color}`,
                    // fontSize: `${this.state.size}px`,
                    fontFamily: this.state.activeFont,
                  }}
                  rows={lines}
                />
                <PlaqueLogo color={location === 'ca' ? '#ffffff' : active.color} />
              </div>

              <div className="plaque__help">
                <em>You can add 2 lines, each up to 14 characters.</em>
                <p>
                  <strong>NOTE: </strong>Only ' " , . * are allowed. No special characters or
                  symbols are supported.
                </p>
              </div>

              <div className="plaque__actions">
                <span
                  className="btn btn--border"
                  onClick={() => {
                    const { defaultState } = this.state;
                    this.setState({
                      ...defaultState,
                    });
                  }}
                >
                  Cancel
                </span>
                <button
                  disabled={this.state.inputText.trim() === ''}
                  className="btn btn--primary"
                  onClick={() => this.updatePlaqueStyle()}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </Box>
      </div>
    );
  }
}
