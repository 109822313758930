import React from "react";
import "./model.style.css";
import ProductMessage from "./ProductMessage";
import ProductStatus from "./ProductStatus";
import { flexrstepImages } from "./data";

const ModelCards = ({
  type,
  selectIndex,
  cardIndex,
  activeIndex,
  onVariantSelect,
  name,
  dimension,
  price,
  image,
  data,
  location,
  status,
  variants,
  activeVariant
}) => {
  let isActive = activeIndex === cardIndex ? "model-card active" : "model-card";

  if (status?.code === 4) {
    return null;
  } else if (status?.code === 2) {
    isActive += " inactive";
  }

  const productDetail = {
    name,
    dimension,
    price
  };

  const handleClick = () => {
    if (data) {
      selectIndex(cardIndex, productDetail);
    }
  };

  let select = null;

  if (variants && Object.keys(variants).length !== 0) {
    select = (
      <select
        name="options"
        onChange={event => onVariantSelect(event.target.value, productDetail)}
      >
        <option value="">Select Option</option>
        {Object.entries(variants).map(([key, value], i) => (
          <option key={i} value={key}>
            {value}
          </option>
        ))}
      </select>
    );
  }

  if (type === "accessories") {
    return (
      <div>
        <div className="select-options">{select}</div>
        <div
          className={isActive}
          onClick={() => selectIndex(cardIndex, productDetail)}
        >
          <div className="model-card__wrapper">
            <ProductStatus status={status} />
            <h2 className="model-card__heading">{name}</h2>
            <p className="model-card__price">{price}</p>
            <p className="model-card__image">
              <img
                src={
                  activeVariant && name == "FlexrStep"
                    ? flexrstepImages.find(x => x.sku == activeVariant).image
                    : image
                }
                alt=""
              />
            </p>
            <ProductMessage status={status} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ opacity: data ? 1 : 0.6 }}
      className={isActive}
      onClick={handleClick}
    >
      <div className="model-card__wrapper">
        <ProductStatus status={status} />
        <h2 className="model-card__heading">{name}</h2>
        <p className="model-card__price">
          {price ? "$" + price[location] : "Coming soon!"}
        </p>
        <p className="model-card__dimension">{dimension} ft</p>
        <p className="model-card__image">
          <img src={image} alt="" />
        </p>
        <ProductMessage status={status} />
      </div>
    </div>
  );
};

export default ModelCards;
