/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { productData } from "./Data";
import ColorOptions from "./Components/ColorOptions";
import Img from "./Components/3D-img";
import PlaceholderTrampolineImg from "./Components/Img";
import Order from "./Components/Order";
import SmoothScrolling from "../../helpers/smoothScrolling";
import { spec } from "../../helpers/defaultSpec";
import Accessories from "./Components/Accessories";
import ProductSelector from "./Components/ProductSelector";
import { themes } from "./Components/ProductSelector/themes";
import ThemePicker from "./Components/ProductSelector/ThemePicker";
import Plaque from "./Components/Plaque";
import "./Styles/steps.css";
import { accessoryData } from "./Components/Accessories/data";
import Share from "./Components/Share";
import { parseUrl } from "../../helpers/urlParser";
import { updateTrampolineImage } from "../../helpers/updateMaterial";
import { lang } from "../../helpers/language";
import { addInventoryStatus } from "../../helpers/addInventoryStatus";
import sendAnalytics from "../../helpers/sendAnalytics";

export const customizeSteps = [
  {
    label: "Nametag Design",
    type: "plaque"
  },
  {
    label: "Accessories",
    type: "accessories"
  },
  {
    label: "Finalise your design",
    type: "order"
  }
];
export default class Screens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      steps: [], // steps for customizing or picking theme
      customize: customizeSteps,
      spec: { ...spec },
      activeStep: 0, // default 0
      activeCustom: -1, // default -1
      products: productData, // all products data
      productAccessoriesList: accessoryData,
      customizationOption: null, // can be 'theme' || 'custom'
      selectedTheme: null,
      activeIndex: -1,
      activeProduct: {},
      showNext: false,
      order: false,
      position: 0,
      stick: false,
      selectedAccessories: [],
      activeVariants: [],
      oldAccessories: [],
      fontDetail: {
        activeFont: "MeatBall",
        fontText: "",
        fontSize: 14,
        lineOne: "",
        lineTwo: "",
        color: "ffffff",
        plaqueColor: "ffffff",
        isCustom: false,
        fromUrl: false
      },
      materialUuids: {},
      legMaterials: [],
      frameMaterials: [],
      MatRodMaterials: [],
      EnclosurerodsMaterials: [],
      orderStatus: false,
      fromdealer: false,
      canvasLoaded: false,
      materialLoaded: true,
      productLoaded: false,
      showProducts: false,
      firstTime: true,
      homePageLoad: true,
      sayduckInstance: null
    };
  }

  async componentDidMount() {
    // console.log("Props inside componentDidMount: ", this.props)
    // load inventory status data
    const productDataWithInventory = await addInventoryStatus(
      productData,
      accessoryData
    );

    if (productDataWithInventory.product) {
      this.setState({
        products: productDataWithInventory.product,
        productAccessoriesList: productDataWithInventory.accessory,
        showProducts: true
      });
    }

    // * Check if there are url paramerts
    // * update the sate based on that
    const { search } = this.props.history.location;
    // console.log("Search: ", search);
    if (search) {
      const fromUrl = parseUrl(search);
      if (fromUrl.dealer) {
        this.setState({ fromdealer: fromUrl.dealer });
      }

      // if the product is already selected
      if (fromUrl.selectedModel) {
        this.checkAndUpdatePassedProduct(fromUrl.selectedModel);
      }

      if (fromUrl.status) {
        const {
          customizationOption,
          activeProduct,
          steps,
          selectedAccessories,
          orderId
        } = fromUrl;

        let themeData = null;
        if (fromUrl.selectedTheme) {
          themeData = themes.find(theme => theme.id === fromUrl.selectedTheme);
          // add plaque details from the url
          themeData.fontDetail = fromUrl.fontDetail;
        }
        this.setState({
          activeStep: fromUrl.activeStep,
          activeCustom: fromUrl.activeCustom,
          activeIndex: fromUrl.activeIndex,
          order: fromUrl.order,
          spec: fromUrl.spec,
          fontDetail: fromUrl.fontDetail,
          activeProduct,
          steps,
          customizationOption,
          selectedTheme: themeData,
          fromUrl: true,
          showNext: true,
          selectedAccessories,
          oldAccessories: [...selectedAccessories],
          orderId
        });
      }
    }

    //UPDATE CUSTOM STEPS BASED ON ACTIVE PRODUCT
    var customSteps = Object.assign([], customizeSteps);
    if (this.state.activeProduct?.nametag === false) {
      var index = customSteps.findIndex(c => c.type === "plaque");
      customSteps.splice(index, 1);
    }
    this.setState({
      customize: customSteps
    });

    /**
     * Event listener to disable closing and navigating back
     */
    const self = this;
    window.addEventListener("scroll", function(e) {
      self.setState(prevState => {
        if (self.state.position > 250) {
          if (!self.state.stick) {
            return {
              position: window.pageYOffset,
              stick: true
            };
          } else {
            return {
              position: window.pageYOffset
            };
          }
        } else if (self.state.position < 40) {
          return {
            position: window.pageYOffset,
            stick: false
          };
        } else {
          return {
            position: window.pageYOffset
          };
        }
      });
    });

    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    window.removeEventListener("sayduck.editor.gltfLoaded", () => {});
    window.removeEventListener("sayduck.api-ready", () => {});
  }

  componentDidUpdate(prevState) {
    const self = this;
    if (
      self.state.activeStep === 1 &&
      self.state.materialLoaded &&
      !self.state.productLoaded
    ) {
      self.setState({ materialLoaded: false });
      let { spec } = self.state;
      const {
        legMaterials,
        frameMaterials,
        MatRodMaterials,
        EnclosurerodsMaterials,
        activeProduct: { id }
      } = self.state;
      if (
        self.state.customizationOption === "theme" &&
        self.state.selectedTheme
      ) {
        spec = self.state.selectedTheme.spec;
      }

      if (id && prevState.canvasLoaded !== self.state.canvasLoaded) {
        updateTrampolineImage(
          self.state.sayduckInstance,
          spec,
          legMaterials,
          frameMaterials,
          MatRodMaterials,
          EnclosurerodsMaterials,
          id
        );
        self.setState({ materialLoaded: true, productLoaded: true });
      }
    }

    try {
      window.removeEventListener("sayduck.editor.gltfLoaded", () => {});
      window.removeEventListener("sayduck.api-ready", () => {});
    } catch (e) {
      console.log(e);
    }

    window.addEventListener("sayduck.editor.gltfLoaded", function(event) {
      if (!self.state.canvasLoaded) {
        self.setState({ canvasLoaded: true });
      }
    });

    window.addEventListener("sayduck.api-ready", event => {
      if (!this.state.sayduckInstance) {
        const api = event.detail.instance;
        self.setState({ sayduckInstance: api.scene });
      }
    });
  }

  /**
   * Select the active product
   * @param {number} selection - position of the product in the list
   */
  async selectActiveProduct(selection) {
    const activeProduct = this.state.products[selection];
    const steps = this.state.products[selection].data;
    if (steps.length > 0) {
      this.toggleNext(true);
    }

    this.setState({
      canvasLoaded: false
    });

    //UPDATE CUSTOM STEPS BASED ON ACTIVE PRODUCT
    var customSteps = Object.assign([], customizeSteps);
    if (activeProduct?.nametag === false) {
      var index = customSteps.findIndex(c => c.type === "plaque");
      customSteps.splice(index, 1);
    }

    await this.setState({
      activeIndex: selection,
      activeProduct,
      steps,
      customize: customSteps
    });

    /**
     * stay in position in mobile view
     * after the title is visible
     * only do it once when there is not title
     * otherwise it hijacks the scroll
     * TODO: add a user agent test
     */
    // if (!this.state.activeProduct.name) {
    //   setTimeout(() => {
    //     SmoothScrolling.scrollTo('selectors');
    //   }, 500);
    // }
  }

  /**
   * Pick the customization option
   * @param {string} type - can be 'theme' || 'custom'
   * if the type is 'theme', update the steps for theme
   * else keep it same for color picking
   * change the step to the first one
   */
  pickCustomizationOption(type) {
    let newState = {
      customizationOption: type,
      activeStep: 1
    };
    if (type === "theme") {
      newState.steps = [themes];
    }
    this.setState(newState);
  }

  /**
   * Select a theme
   * @param {object} theme - theme data of the selected theme
   */
  selectTheme(theme) {
    // const {
    //   spec,
    //   legMaterials,
    //   frameMaterials,
    //   MatRodMaterials,
    //   EnclosurerodsMaterials,
    //   activeProduct,
    // } = this.state;
    this.setState({
      selectedTheme: theme,
      showNext: true
    });
  }

  /**
   * Update the step based on the selected value
   * @param {number} selected - index of the step
   */
  changeStep(selected) {
    if (this.state.activeStep === 0) {
      this.setState(prevState => {
        prevState.activeStep += 1;
        return {
          activeStep: prevState.activeStep,
          customizationOption: "custom"
        };
      });
    } else if (selected <= this.state.steps.length) {
      this.setState({ activeStep: selected });
      // scroll to the top
      SmoothScrolling.scrollTo("root");
    }
    // if it is the order screen
    else if (
      selected ===
      this.state.steps.length + this.state.customize.length
    ) {
      this.setState(prevState => {
        prevState.activeCustom += 1;
        return {
          activeCustom: prevState.activeCustom,
          activeStep: selected,
          order: true
        };
      });
    } else {
      this.setState(prevState => {
        prevState.activeCustom += 1;
        return { activeCustom: prevState.activeCustom, activeStep: selected };
      });
    }

    /**
     * If the next step already has colors selected
     * or the steps are generated from the URL
     * keep the button active
     * else hide the button
     */
    let active = false;

    if (
      (this.state.steps[selected - 1] &&
        this.state.steps[selected - 1].selection) ||
      this.state.fromUrl
    ) {
      active = true;
    }
    if (this.state.activeStep > this.state.steps.length - 1) {
      // if this is the last step of customization
      active = true;
    }

    this.toggleNext(active);
  }

  /**
   * Keep the state data of the active product
   * @param {number} index - Index position of the selection
   */
  updateColorSelection(index, arrangementColorId) {
    // add a selection key with the selected arrangementColorId
    this.setState(prevState => {
      const targetPart = prevState.steps[index];
      let color = 0;
      if (parseInt(arrangementColorId) === 0) {
        prevState.spec[targetPart.id].arrangement = "1";
      }
      if (parseInt(arrangementColorId) !== 0) {
        color = targetPart.config[parseInt(arrangementColorId) - 1].id; // The arrangement index should compensate for array position 0
      }
      targetPart.selection = {
        color,
        arrangement: arrangementColorId === "0" ? "1000" : "",
        pickedColors: []
      };

      return {
        steps: prevState.steps,
        showNext: false,
        spec: prevState.spec
      };
    });
  }

  /**
   * Update the arrangement of the color selected
   * @param {number} colorIndex - index of the color choice
   * @param {string} arrangementId - id of the arrangement
   */
  updateArrangementValue(partIndex, colorIndex, arrangement) {
    const {
      spec,
      activeStep,
      legMaterials,
      frameMaterials,
      MatRodMaterials,
      EnclosurerodsMaterials,
      activeProduct: { id }
    } = this.state;
    this.setState(
      prevState => {
        const targetPart = this.state.steps[partIndex];
        targetPart.selection["arrangement"] = arrangement.val;

        // add the arrangement to the image url
        const urlColor = arrangement.val.slice(0, 1);
        prevState.spec[
          targetPart.id
        ].arrangement = `${urlColor}${arrangement.selectedIndex}`;

        return {
          steps: prevState.steps,
          spec: prevState.spec
        };
      },
      () => {
        updateTrampolineImage(
          this.state.sayduckInstance,
          spec,
          legMaterials,
          frameMaterials,
          MatRodMaterials,
          EnclosurerodsMaterials,
          id,
          activeStep
        );
      }
    );
  }

  /**
   * Update the list of selected colors
   * @param {array} colors - array with the selected sequence of colors
   */
  listOfColors(partIndex, colors) {
    const {
      spec,
      activeStep,
      legMaterials,
      frameMaterials,
      MatRodMaterials,
      EnclosurerodsMaterials,
      activeProduct: { id }
    } = this.state;
    this.setState(
      prevState => {
        const targetPart = prevState.steps[partIndex];
        /**
         * assign colors for the image url to config object
         * loop through the existing values
         * assign them to respective part
         */
        colors.forEach((colorVal, index) => {
          /**
           * the label of the color is 1 more than the index
           * the id of the targetPart is uppercase the part key is lowercase, so make the change
           * remove # from color value
           */
          prevState.spec[targetPart.id][
            `${targetPart.id.toLowerCase()}${index + 1}`
          ] = colorVal.slice(1);
        });

        // if selection key doesn't exist
        if (!targetPart.selection) {
          targetPart.selection = {
            color: 0,
            pickedColors: colors,
            arrangement: "1000"
          };
        }

        return {
          steps: prevState.steps,
          spec: prevState.spec
        };
      },
      () => {
        updateTrampolineImage(
          this.state.sayduckInstance,
          spec,
          legMaterials,
          frameMaterials,
          MatRodMaterials,
          EnclosurerodsMaterials,
          id,
          activeStep
        );
      }
    );

    const activePart = this.state.steps[partIndex];
    // get the id of the color selection to get maximum value
    let max = 1;
    if (activePart.selection) {
      max = parseInt(activePart.config[activePart.selection.color].id);
    }

    if (colors.length === max) {
      this.toggleNext(true);
    } else {
      this.toggleNext(false);
    }
  }

  /*/ steps link*/
  stepsLink(cIndex = null) {
    cIndex = cIndex ?? this.state.activeStep;

    if (cIndex > 0 && this.state.steps[cIndex]) {
      return this.state.steps[cIndex].label.toLowerCase().replace(/\s/g, "-");
    }

    if (cIndex > this.state.steps.length - 1) {
      return this.state.customize[cIndex - this.state.steps.length].label
        .toLowerCase()
        .replace(/\s/g, "-");
    }

    return "legs";
  }

  /**
   * Switch the steps
   * @param {number} index - index of the step
   */
  switchStep(index) {
    this.setState({
      activeStep: index + 1,
      order: false
    });

    // Scroll to top
    SmoothScrolling.scrollTo("root");

    /**
     * The values are already there so activate next button
     */
    this.toggleNext(true);

    window.history.replaceState(
      {
        url:
          "/design/" + this.state.activeProduct.id + "/" + this.stepsLink(index)
      },
      document.title,
      "/design/" + this.state.activeProduct.id + "/" + this.stepsLink(index)
    );
    sendAnalytics();
  }

  /**
   * Show or hide the next button
   * @param {boolean} status
   */
  toggleNext(status) {
    this.setState(prevState => {
      return {
        showNext: status
      };
    });
  }

  /**
   * Update order status
   * needed for share screen message
   */
  updateOrderStatus(status) {
    this.setState({
      orderStatus: status
    });
  }

  /**
   * Update selected accessories
   * @param {array} accessoriesList - list of all the selected accessories
   */
  updateSelectedAccessories(accessoriesList) {
    this.setState({
      selectedAccessories: accessoriesList
    });
  }
  updateSelectedVariants(variantList) {
    console.log('variantList',variantList);
    this.setState({
      activeVariants: variantList
    });
  }

  /**
   * Render the selector view based on active step
   */
  renderSelectors() {
    /**
     * Select the product
     * select the option to start from scratch or theme
     */
    if (this.state.activeStep < 1) {
      if (!this.state.showProducts) {
        return null;
      }
      return (
        <ProductSelector
          products={this.state.products}
          activeIndex={this.state.activeIndex}
          activeStep={this.state.activeStep}
          pickCustomizationOption={type => this.pickCustomizationOption(type)}
          selectIndex={(cardIndex, product) =>
            this.selectActiveProduct(cardIndex, product)
          }
          location={this.state.location}
          selectedModel={this.state.activeProduct}
        />
      );
    }

    /**
     * show color selector
     * when the customization option is custom
     */
    if (
      this.state.activeStep >= 0 &&
      this.state.activeStep <= this.state.steps.length &&
      this.state.customizationOption === "custom"
    ) {
      const partIndex = parseInt(this.state.activeStep) - 1;
      return (
        <ColorOptions
          part={this.state.steps[partIndex]}
          updateColorSelection={arrangementValue =>
            this.updateColorSelection(partIndex, arrangementValue)
          }
          updateArrangementValue={(selectedColor, value) =>
            this.updateArrangementValue(partIndex, selectedColor, value)
          }
          listOfColors={colors => this.listOfColors(partIndex, colors)}
          activeProduct={this.state.activeProduct}
        />
      );
    }

    /**
     * show preset themes
     * when the theme is selected
     */
    if (
      this.state.activeStep >= 0 &&
      this.state.activeStep <= this.state.steps.length &&
      this.state.customizationOption === "theme"
    ) {
      let productId = this.state.activeProduct.id;
      return (
        <ThemePicker
          themes={this.state.steps[0]}
          productId={productId}
          selectedTheme={this.state.selectedTheme}
          selectTheme={theme => this.selectTheme(theme)}
          location={this.state.location}
        />
      );
    }

    // Other screens
    const activeCustomizer = this.state.customize[
      this.state.activeStep - (this.state.steps.length + 1)
    ];
    const state = this.state;
    switch (activeCustomizer.type) {
      case "plaque":
        return (
          <Plaque
            {...state}
            updateFont={fontDetail => {
              this.setState({
                fontDetail
              });
            }}
            updateThemeFont={fontDetail => {
              this.setState(prevState => {
                let updatedValue = prevState.selectedTheme.fontDetail;
                updatedValue.activeFont = fontDetail.activeFont;
                updatedValue.fontText = fontDetail.fontText;
                updatedValue.fontSize = fontDetail.fontSize;
                updatedValue.lineOne = fontDetail.lineOne;
                updatedValue.lineTwo = fontDetail.lineTwo;
                updatedValue.plaqueColor = fontDetail.plaqueColor;

                // update the font value in theme
                prevState.selectedTheme.fontDetail = updatedValue;

                return {
                  selectedTheme: prevState.selectedTheme
                };
              });
            }}
          />
        );

      case "accessories":
        return (
          <Accessories
            activeVariants={this.state.activeVariants}
            activeProductId={this.state.activeProduct.id}
            activeProductDetails={this.state.activeProduct}
            selectedAccessories={this.state.selectedAccessories}
            location={this.props.location}
            updateSelectedAccessories={accessoriesList =>
              this.updateSelectedAccessories(accessoriesList)
            }
            updateSelectedVariants={variantList =>
              this.updateSelectedVariants(variantList)
            } 
          />
        );

      default:
        // specification for the liquid pixel image
        let selectedSpec = this.state.spec;
        let activeFontDetail = this.state.fontDetail;
        if (this.state.selectedTheme) {
          selectedSpec = this.state.selectedTheme.spec;
          activeFontDetail = this.state.selectedTheme.fontDetail;
        }
        return (
          <Order
            spec={selectedSpec}
            fontDetail={activeFontDetail}
            selectedAccessories={this.state.selectedAccessories}
            activeVariants={this.state.activeVariants}
            oldAccessories={this.state.oldAccessories}
            state={this.state}
            country={this.state.location}
            product={this.state.activeProduct.id}
            pin={this.props.pin}
            updateOrderStatus={val => {
              this.updateOrderStatus(val === 1);
            }}
            skipPin={this.props.skipPin}
            contactDetail={this.props.contactDetail}
            dealer={this.state.fromdealer}
            onUnload={this.onUnload}
            canvasLoaded={this.state.canvasLoaded}
          >
            {this.renderStepData()}
          </Order>
        );
    }
  }

  /**
   * Render the steps in the product detail
   */
  renderStepData() {
    const {
      activeStep,
      steps,
      customize,
      customizationOption,
      fontDetail,
      fromUrl,
      selectedTheme,
      selectedAccessories
    } = this.state;
    const allSteps = [...steps, ...customize];

    return allSteps.map((step, index) => {
      if (step.type === "order") {
        return null;
      }

      // check if the values are already selected or not
      const inActiveState = () => {
        // selected theme
        if (customizationOption === "theme" && selectedTheme) {
          return true;
        }

        // customiztion done
        if (step.selection) {
          return true;
        }

        // coming from url
        if (fromUrl) {
          return true;
        }

        // for plaque selected
        if (step.type === "plaque" && fontDetail.isCustom) {
          return true;
        }

        if (step.type === "accessories" && selectedAccessories.length < 1) {
          return false;
        }

        // for selected accessories
        if (step.type === "accessories") {
          return true;
        }

        return false;
      };
      const currentStep = activeStep === index + 1;

      let stepClass = "steps__wrapper";
      if (currentStep) {
        stepClass += " active";
      }

      return (
        <div
          key={`${index}${step.label}`}
          className={stepClass}
          onClick={() => {
            if (inActiveState()) {
              this.switchStep(index);
            }
          }}
          style={{
            opacity: inActiveState() || currentStep ? 1 : 0.4
          }}
        >
          <h3 className="steps__label">
            <img src="/img/edit-white.svg" alt="edit" className="icon--edit" />{" "}
            <span>
              {customizationOption === "theme" && index === 0
                ? "Theme"
                : step.label}
            </span>
          </h3>
          <div>
            {step.selection &&
              step.selection.pickedColors.map((col, index) => {
                return (
                  <span
                    className="steps__color"
                    style={{
                      backgroundColor: col,
                      marginLeft: index > 0 ? "-8px" : 0
                    }}
                    key={index}
                  />
                );
              })}
          </div>
        </div>
      );
    });
  }

  /**
   * Final list of selected accessories on the orders screen*
   */
  renderAccessoriesList() {
    return (
      <div className="accessories__list">
        {this.state.selectedAccessories.map(accessory => {
          const accessoryItem = this.state.productAccessoriesList.find(
            item => item.name === accessory
          );
          const country = this.state.location,
            productId = this.state.activeProduct.id;
          return (
            <div className="accessories__list--item">
              <img
                src={accessoryItem.image}
                alt={accessoryItem.name}
                className="accessories__list--image"
              />
              <div>
                <h3 className="model-card__heading">{accessoryItem.name}</h3>
                <span className="model-card__price">
                  {accessoryItem[country][productId]}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  onUnload(event) {
    event.preventDefault();
    if (this.state.homePageLoad)
      return (event.returnValue = "Changes you have made may not be saved");
    // the method that will be used for both add and remove event
  }

  /**
   * Reset tool
   * set the primary state
   */
  resetTool() {
    /*let confirmed = window.confirm(
      'This will reset everything and take you to "Product selection."'
    );

    if (confirmed) {
      this.setState({
        steps: [], // steps for customizing or picking theme
        spec: JSON.parse(initialSpec),
        products: JSON.parse(initialProductData),
        activeStep: 0, // default 0
        activeCustom: -1, // default -1
        customizationOption: null, // can be 'theme' || 'custom'
        selectedTheme: null,
        activeIndex: -1,
        activeProduct: {},
        showNext: false,
        order: false,
        position: 0,
        stick: false,
        selectedAccessories: [],
        fontDetail: {
          activeFont: "MeatBall",
          fontText: "",
          fontSize: 14,
          lineOne: "",
          lineTwo: "",
          color: "ffffff",
          plaqueColor: "ffffff",
          isCustom: false,
          fromUrl: false,
        },
      });
    }*/

    window.location.href = "/design";
  }

  /** get Materials for the trampoline */
  async getMaterialUuids(materialuuids) {
    if (materialuuids.materials) {
      await this.setState({ materialUuids: materialuuids.materials }, () => {
        this.sortMaterials();
      });
    }
  }

  /** Sort trampoline materials  */
  sortMaterials() {
    const { materialUuids } = this.state;
    let {
      legMaterials,
      EnclosurerodsMaterials,
      frameMaterials,
      MatRodMaterials
    } = this.state;
    legMaterials = [];
    MatRodMaterials = [];
    EnclosurerodsMaterials = [];
    frameMaterials = [];
    for (const material in materialUuids) {
      if (materialUuids[material].name.includes("Leg")) {
        let leg = materialUuids[material];
        legMaterials.push(leg);
        legMaterials.sort(function(a, b) {
          return (
            parseInt(a.name.match(/^(\D*)(\d+)/)[2]) -
            parseInt(b.name.match(/^(\D*)(\d+)/)[2])
          );
        });
        this.setState({ legMaterials: legMaterials });
      } else if (materialUuids[material].name.includes("Metal")) {
        MatRodMaterials.push(materialUuids[material]);
        MatRodMaterials.sort(function(a, b) {
          return (
            parseInt(a.name.match(/^(\D*)(\d+)/)[2]) -
            parseInt(b.name.match(/^(\D*)(\d+)/)[2])
          );
        });
        this.setState({ MatRodMaterials: MatRodMaterials });
      } else if (materialUuids[material].name.includes("Enclosure")) {
        if (materialUuids[material].name.includes("Top")) {
          return;
        }
        EnclosurerodsMaterials.push(materialUuids[material]);
        EnclosurerodsMaterials.sort(function(a, b) {
          return (
            parseInt(a.name.match(/^(\D*)(\d+)/)[2]) -
            parseInt(b.name.match(/^(\D*)(\d+)/)[2])
          );
        });
        this.setState({ EnclosurerodsMaterials: EnclosurerodsMaterials });
      } else if (materialUuids[material].name.includes("Frame")) {
        let frame = materialUuids[material];
        frameMaterials.push(frame);
        frameMaterials.sort(function(a, b) {
          return (
            parseInt(a.name.match(/^(\D*)(\d+)/)[2]) -
            parseInt(b.name.match(/^(\D*)(\d+)/)[2])
          );
        });
        this.setState({ frameMaterials: frameMaterials });
      }
    }

    // document.addEventListener("sayduck.viewer.material.update");
  }

  checkAndUpdatePassedProduct(productId) {
    let activeProduct = this.state.products.find(product => {
      return product.id === productId;
    });

    if (!activeProduct) {
      // redirect to the main screen and remove params
      window.location.href = "/design";
      return;
    }

    let activeIndex = this.state.products.findIndex(product => {
      return product.id === activeProduct.id;
    });

    const steps = this.state.products[activeIndex].data;
    this.setState({
      activeProduct: activeProduct,
      customizationOption: "custom",
      activeIndex: activeIndex,
      showNext: false,
      steps,
      activeStep: 1,
      firstTime: false
    });

    window.history.replaceState(
      { url: "/design/" + activeProduct.id + "/legs" },
      document.title,
      "/design/" + activeProduct.id + "/legs"
    );
    sendAnalytics();
  }

  setToStart() {
    this.setState({
      activeIndex: -1,
      activeProduct: {},
      activeStep: 0,
      firstTime: true
    });
  }

  setFirstTime() {
    this.setState({
      firstTime: false
    });
  }

  setHomePageLoad(status) {
    this.setState({ homePageLoad: status });
  }

  render() {
    const { pin, location } = this.props;
    const activeProduct = this.state.activeProduct;
    const { activeStep, steps, customize } = this.state;
    // const {
    //   activeStep,
    //   steps,
    //   customize,
    //   legMaterials,
    //   frameMaterials,
    //   MatRodMaterials,
    //   EnclosurerodsMaterials,
    // } = this.state;
    /*const selections = () => {
      if (
        this.state.steps[this.state.activeStep - 1] &&
        this.state.steps[this.state.activeStep - 1].selection
      ) {
        return this.state.steps[this.state.activeStep - 1].selection;
      } else {
        return null;
      }
    };

    let color1 = "000000";
    if (selections() && selections().pickedColors[0]) {
      color1 = selections().pickedColors[0].slice(1);
    }*/

    // sticky view in responsive
    let imageClass = "product__container";
    if (this.state.stick) {
      imageClass = "product__container small";
    }
    if (!this.state.activeProduct?.id) {
      imageClass += " product-selection__wrapper";
    }

    let pageClass = "product__wrapper";

    // if is customization option choice
    if (this.state.activeStep === 0.1) {
      pageClass += " customize";
    }

    if (
      this.state.activeStep > 0.5 &&
      this.state.activeStep <= this.state.steps.length
    ) {
      pageClass += " steps";

      if (this.state.customizationOption === "theme") {
        pageClass += " theme-selector";

        if (this.state.selectedTheme) {
          pageClass += " is-active";
        }
      }
    }
    if (this.state.activeStep === 0) {
      pageClass += " home";
    }

    // for the pages after the steps
    if (this.state.activeStep > this.state.steps.length) {
      pageClass += ` ${
        this.state.customize[
          this.state.activeStep - (this.state.steps.length + 1)
        ].type
      }`;
    }

    // specification for the liquid pixel image
    let spec = this.state.spec;
    let activeFontDetail = this.state.fontDetail;
    if (this.state.selectedTheme) {
      spec = this.state.selectedTheme.spec;
      activeFontDetail = this.state.selectedTheme.fontDetail;
    }

    // let imgUrl = '/img/main.png';
    let productId = this.state.activeProduct.id;

    // steps lable
    const stepsLabel = () => {
      if (activeStep > 0 && steps[this.state.activeStep]) {
        return steps[activeStep].label;
      }

      if (activeStep > steps.length - 1) {
        return customize[activeStep - steps.length].label;
      }

      return "Customize";
    };

    return (
      <Router>
        <Switch>
          <Route
            exact
            path={[
              "/design",
              "/design/",
              "/design/:productId",
              "/design/:productId/:activeTab"
            ]}
            render={props => {
              //MANAGE GOING BACK TO HOME PAGE
              if (
                props.location.pathname === "/design" &&
                !this.firstTime &&
                !this.state.homePageLoad
              ) {
                window.location.href = "/design";
                return null;
              } else if (
                props.location.pathname !== "/design" &&
                this.state.homePageLoad
              ) {
                this.setHomePageLoad(false);
              }
              //IF PRODUCT ID PASSED THEN CHECK FOR ACTIVE PRODUCT OR ELSE MAKE THE ROUTE "/DESIGN"
              if (
                props.match.params.productId &&
                !this.state.activeProduct.id
              ) {
                this.checkAndUpdatePassedProduct(props.match.params.productId);
              } else if (
                !props.match.params.productId &&
                this.state.activeProduct.id &&
                this.state.activeStep > 0 &&
                !props.history.location.search?.length
              ) {
                this.setToStart();
              }
              return (
                <section className="container">
                  <div className={pageClass}>
                    <div className="product__image--wrapper">
                      <header className="product__header">
                        <a rel="noopener" href={lang.url[location]}>
                          <img
                            src="/img/springfree.svg"
                            alt="Springfree logo"
                            className="logo"
                          />
                        </a>
                        <div className="product__header--actions">
                          {pin && (
                            <span className="user__pin">
                              {lang.zip[location]}: <strong>{pin}</strong>
                            </span>
                          )}
                          {activeStep > 0 && (
                            <span
                              onClick={() => this.resetTool()}
                              className="btn btn--border btn--small"
                            >
                              Reset
                            </span>
                          )}
                        </div>
                      </header>
                      <div className={imageClass}>
                        <div
                          className={
                            this.state.order
                              ? "order-product__image"
                              : "product__image"
                          }
                        >
                          {productId ? (
                            <Img
                              productId={productId}
                              getMaterialUuids={materialUuids =>
                                this.getMaterialUuids(materialUuids)
                              }
                              canvasLoaded={this.state.canvasLoaded}
                              resetSayduckInstance={() => {
                                if (this.state.sayduckInstance) {
                                  this.setState({ sayduckInstance: null });
                                }
                              }}
                            />
                          ) : (
                            <PlaceholderTrampolineImg imgUrl="/img/main.jpg" />
                          )}
                        </div>
                        {activeProduct.name && !this.state.order && (
                          <div className="product__information">
                            <div>
                              <h1 className="product__information--name">
                                {activeProduct.name}
                                <span className="product__information--label">
                                  Trampoline
                                </span>
                              </h1>
                              <h2 className="product__information--price">
                                ${activeProduct.price[location]}
                              </h2>
                              <p style={{'display':'flex','align-items':'center'}}>Interest free financing options available at checkout</p>

                              {this.state.order && this.renderAccessoriesList()}

                              {!this.state.order &&
                                this.state.activeStep !== 0.1 && (
                                  <div
                                    className={
                                      this.state.canvasLoaded &&
                                      this.state.materialLoaded &&
                                      this.state.showNext
                                        ? "action__next"
                                        : "action__next disable"
                                    }
                                    onClick={() => {
                                      this.changeStep(
                                        Math.round(this.state.activeStep + 1)
                                      );

                                      //SETTING UP PROPS FOR FIRST REDIRECTION FOR REST ONLY THE LINK
                                      if (this.stepsLink() === "legs") {
                                        props.history.push(
                                          "/design/" +
                                            activeProduct.id +
                                            "/" +
                                            this.stepsLink()
                                        );
                                        this.setFirstTime();
                                      } else {
                                        window.history.replaceState(
                                          {
                                            url:
                                              "/design/" +
                                              activeProduct.id +
                                              "/" +
                                              this.stepsLink()
                                          },
                                          document.title,
                                          "/design/" +
                                            activeProduct.id +
                                            "/" +
                                            this.stepsLink()
                                        );
                                      }
                                      sendAnalytics();
                                    }}
                                  >
                                    <div className="action__next--step">
                                      <span>Next</span>
                                      <span className="action__next--label">
                                        {stepsLabel()}
                                      </span>
                                    </div>
                                    <span className="action__arrow" />
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                        {this.state.order ? (
                          <div
                            className={
                              this.state.order
                                ? "order__wrapper"
                                : "product__cards--wrapper"
                            }
                            id="selectors"
                          >
                            {this.renderSelectors()}
                            {this.state.activeStep > 0.5 && !this.state.order && (
                              <div className="steps-section">
                                <h4 className="steps-section--heading">
                                  Edit configuration
                                </h4>
                                <div className="product__information--steps">
                                  {this.renderStepData()}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {!this.state.order ? (
                      <div
                        className={
                          this.state.order
                            ? "order__wrapper"
                            : "product__cards--wrapper"
                        }
                        id="selectors"
                      >
                        {this.renderSelectors()}
                        {this.state.activeStep > 0.5 && !this.state.order && (
                          <div className="steps-section">
                            <h4 className="steps-section--heading">
                              Edit configuration
                            </h4>
                            <div className="product__information--steps">
                              {this.renderStepData()}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>

                  {
                    // !this.state.order && (
                    // <Prompt
                    //   when={this.state.pinCheck}
                    //   message="You have unsaved changes, are you sure you want to leave?"
                    // />

                    <Prompt
                      when={!this.state.firstTime}
                      message="You have unsaved changes, are you sure you want to leave?"
                    />
                    // )
                  }
                </section>
              );
            }}
          />
          <Route
            path="/design/share"
            render={() => {
              return (
                <Share
                  spec={spec}
                  productId={productId}
                  fontDetail={this.state.fontDetail}
                  orderStatus={this.state.orderStatus}
                  place={this.state.location}
                  location={this.props.history.location}
                />
              );
            }}
          />
        </Switch>
      </Router>
    );
  }
}
