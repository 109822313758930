export const accessoryData = [
  {
    name: 'FlexrHoop',
    id: 'flexHoop',
    image: '/img/accessories/hoop.png',
    us: {
      O77C: '$199',
      O92C: '$199',
      S113C: '$199',
      S155C: '$199',
    },
    ca: {
      O77C: '$199',
      O92C: '$199',
      S113C: '$199',
      S155C: '$199',
    },
    au: {
      O77C: '$199',
      O92C: '$199',
      S113C: '$199',
      S155C: '$199',
    },
    status: {
      code: 1,
      message: '',
    },
  },
  {
    name: 'FlexrStep',
    id: 'flexrStep',
    image: '/img/accessories/step.png',
    us: {
      O77C: '$99',
      O92C: '$99',
      S113C: '$99',
      S155C: '$99',
    },
    ca: {
      O77C: '$119',
      O92C: '$119',
      S113C: '$119',
      S155C: '$119',
    },
    au: {
      O77C: '$119',
      O92C: '$119',
      S113C: '$119',
      S155C: '$119',
      variants: {
        'SP0080': 'Black',
        'SP0181-MintyBlue': 'MintyBlue',
        'SP0181-BLUE': 'BLUE',
        'SP0181-GREEN': 'GREEN',
        'SP0181-GUNMETAL': 'GUNMETAL',
        'SP0181-MintyBlue':'MintyBlue',
        'SP0181-PINK':'PINK',
        'SP0181-RED':'RED',
        'SP0181-SteelyWind':'SteelyWind'
      }
    },
    status: {
      code: 2,
      message: 'No shipment date currently available. Call 1800 586 772 for more info.',
    },
  },
  {
    name: 'Sunshade',
    id: 'sunshade',
    image: '/img/accessories/sunshade.png',
    us: {
      O77C: '$199',
      O92C: '$219',
      S113C: '$239',
      S155C: '$249',
    },
    ca: {
      O77C: '$219',
      O92C: '$239',
      S113C: '$279',
      S155C: '$299',
    },
    au: {
      O77C: '$199',
      O92C: '$199',
      S113C: '$239',
      S155C: '$249',
    },
    status: {
      code: 2,
      message: 'No shipment date currently available. Call 1800 586 772 for more info.',
    },
  },
  {
    name: 'Cover',
    id: 'cover',
    image: '/img/accessories/cover.png',
    us: {
      O77C: '$249',
      O92C: '$249',
      S113C: '$279',
      S155C: '$299',
    },
    ca: {
      O77C: '$269',
      O92C: '$289',
      S113C: '$319',
      S155C: '$349',
    },
    au: {
      O77C: '$249',
      O92C: '$249',
      S113C: '$279',
      S155C: '$299',
    },
    status: {
      code: 1,
      message: '',
    },
  },
  {
    name: 'Ground Anchor',
    id: 'groundAnchor',
    image: '/img/accessories/anchor.png',
    us: {
      O77C: '$79',
      O92C: '$79',
      S113C: '$99',
      S155C: '$139',
    },
    ca: {
      O77C: '$99',
      O92C: '$99',
      S113C: '$99',
      S155C: '$139',
    },
    au: {
      O77C: '$79',
      O92C: '$79',
      S113C: '$99',
      S155C: '$139',
    },
    status: {
      code: 1,
      message: '',
    },
  },
  {
    name: 'Storage Bag',
    id: 'storageBag',
    image: '/img/accessories/storage-bag.png',
    us: {
      O77C: '$29',
      O92C: '$29',
      S113C: '$29',
      S155C: '$29',
    },
    ca: {
      O77C: '$29',
      O92C: '$29',
      S113C: '$29',
      S155C: '$29',
    },
    au: {
      O77C: '$29',
      O92C: '$29',
      S113C: '$29',
      S155C: '$29',
    },
    status: {
      code: 1,
      message: '',
    },
  },
  {
    name: 'Care Pack',
    id: 'carePack',
    image: '/img/accessories/carepack.png',
    us: {
      O77C: '$69',
      O92C: '$69',
      S113C: '$69',
      S155C: '$69',
    },
    ca: {
      O77C: '$69',
      O92C: '$69',
      S113C: '$69',
      S155C: '$69',
    },
    au: {
      O77C: '$69',
      O92C: '$69',
      S113C: '$69',
      S155C: '$69',
    },
    status: {
      code: 1,
      message: '',
    },
  },
  {
    name: 'Shifting Wheels',
    id: 'shiftingWheels',
    image: '/img/accessories/wheels.png',
    us: {
      O77C: '$99',
      O92C: '$99',
      S113C: '$99',
      S155C: '$99',
    },
    ca: {
      O77C: '$99',
      O92C: '$99',
      S113C: '$99',
      S155C: '$99',
    },
    au: {
      O77C: '$99',
      O92C: '$99',
      S113C: '$99',
      S155C: '$99',
    },
    status: {
      code: 1,
      message: '',
    },
  },
  {
    name: 'tgoma',
    id: 'tgoma',
    image: '/img/accessories/tgoma.png',
    us: {
      O77C: '$349',
      O92C: '$349',
      S113C: '$349',
      S155C: '$349',
    },
    ca: {
      O77C: '$399',
      O92C: '$399',
      S113C: '$399',
      S155C: '$399',
    },
    au: {
      O77C: '$399',
      O92C: '$399',
      S113C: '$349',
      S155C: '$349',
    },
    status: {
      code: 1,
      message: 'Ships within 21 business days',
    },
  },
];
