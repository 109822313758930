import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import posed from 'react-pose';
import ColorPicker from './ColorPicker/';
import { colors } from '../../../helpers/trampolineColors';

const Box = posed.div({
  hidden: { height: 0 },
  visible: {
    height: 'auto',
    transition: {
      opacity: { ease: 'easeOut', duration: 200 },
      default: { ease: 'linear', duration: 300 },
    },
  },
});
export default class ColorOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: props.initialKey,
      activeIndex: -1,
      colorValue: 0,
      arrangementValue: 0,
      activeColors: [],
      activeOptions: {
        options: [],
      },
      materialids:{}
    };

    this.changeColorOption = this.changeColorOption.bind(this);
  }


  componentDidMount(){
    this.setState({activeColors:[]})
  }

  /**
   * @param {number} index - index of the arrangement
   * @param {number} id - id of the arrangement
   */
  changeColorOption = event => {
    const { part, updateColorSelection } = this.props;
    const { config } = part;
    const optionValue = event.target.value;
    updateColorSelection(optionValue);
    this.setState({
      activeOptions: config[optionValue],
    });
  };

  /**
   * update arrangement of the selected number of colors
   * @param {object} event - javascript event to get value from the select
   * @param {string} selectedColor - id of the selected colors
   */
  updateArrangement = (event, selectedColor) => {
    const { updateArrangementValue } = this.props;
    const val = event.target.value;
    const selectedIndex = event.target.selectedIndex;
    const data = {
      val,
      selectedIndex,
    };
    updateArrangementValue(selectedColor, data);
  };

  /**
   * Add color to the selection
   * @param {array} activeColors - list of selected colors
   * @param {string} colorPicked - value of the color
   */
 async updateSelectedColor(activeColors, colorPicked) {
    const { listOfColors } = this.props;
    activeColors.push(colorPicked);
    listOfColors(activeColors);
    this.setState({
      activeColors: activeColors,
    });
  }

  /**
   * Remove color from the selection
   * @param {array} activeColors - list of selected colors
   * @param {number} index - index of the color to remove
   */
  dropSelectedColor(activeColors, index) {
    const { listOfColors } = this.props;
    activeColors.splice(index, 1);
    listOfColors(activeColors);

    this.setState({
      activeColors: activeColors,
    });
  }

  render() {
    const { part, activeProduct } = this.props;
    const { config } = part;
    const selectedColor = part.selection ? part.selection.color : 0;
    const activeOptions = config[selectedColor];
    const selectedArrangement = part.selection ? part.selection.arrangement : 1;
    const activeColors = part.selection ? part.selection.pickedColors : [];

    var unavailableColor = [], hiddenColor = [];
    var colorsOptionAvailable = Object.keys(colors).length;

    if(activeProduct.parts)
    {
      switch (part.label.trim()) {
        case "Legs":
          unavailableColor = activeProduct['parts']['parts_legs']['unavailableColor'];
          hiddenColor = activeProduct['parts']['parts_legs']['hiddenColor'];
          break;
        case "Frame":
          unavailableColor = activeProduct['parts']['parts_frames']['unavailableColor'];
          hiddenColor = activeProduct['parts']['parts_frames']['hiddenColor'];
          break;
        case "Mat Rods":
          unavailableColor = activeProduct['parts']['parts_matrods']['unavailableColor'];
          hiddenColor = activeProduct['parts']['parts_matrods']['hiddenColor'];
          break;
          case "Enclosure Rods":
          unavailableColor = activeProduct['parts']['parts_enclosure']['unavailableColor'];
          hiddenColor = activeProduct['parts']['parts_enclosure']['hiddenColor'];
          break;
        default:
          unavailableColor = activeProduct['parts']['parts_legs']['unavailableColor'];
          hiddenColor = activeProduct['parts']['parts_legs']['hiddenColor'];
      }

      colorsOptionAvailable -= (unavailableColor.length + hiddenColor.length);
    }

    return (
      <Box
        className="part__animation"
        style={{ overflow: 'hidden' }}
        onValueChange={part.label}
        poseKey={part.label}
        pose={'visible'}
      >
        <div className="part">
          <div className="part-option__wrapper">
            <h3 className="part__heading">
              Trampoline <strong>{part.label}</strong> design.
              <strong data-tip className="tool-tip__action">
                {' '}
                ?
              </strong>
              <ReactTooltip className="tool-tip__message" place="top" type="info" effect="float">
                <span>
                  Need help with your design? <br />
                  Give us a call, we’re ready to help. <br />
                  <strong>1800 586 772</strong>
                </span>
              </ReactTooltip>
            </h3>
            {part.label == 'Enclosure Rods' }
            <label className="part-option--label">Choose your number of colours</label>
            <select onChange={this.changeColorOption} value={selectedColor}>
              {config.filter(c => (c.id <= colorsOptionAvailable)).map((colorOptions, index) => (
                <option key={colorOptions.id} value={index}>
                  {colorOptions.label}
                </option>
              ))}
            </select>

            {activeOptions &&
              activeOptions.options.length > 0 && [
                <label className="part-option--label">Choose your colour arrangement</label>,
                <select
                  onChange={event => this.updateArrangement(event, selectedColor)}
                  value={selectedArrangement}
                  style={{
                    borderColor: activeOptions && !selectedArrangement ? 'red' : '#303030',
                  }}
                >
                  <option value="0">Pick arrangement</option>
                  {activeOptions.options.map((arrangement, index) => (
                    <option
                      key={`${activeOptions.id}${arrangement.id}`}
                      value={`${activeOptions.id}${arrangement.id}`}
                    >
                      {arrangement.label}
                    </option>
                  ))}
                </select>,
              ]}
          </div>
          {
            activeProduct.parts
            ?
            <ColorPicker
              updateSelectedColor={colorPicked => this.updateSelectedColor(activeColors, colorPicked)}
              dropSelectedColor={index => this.dropSelectedColor(activeColors, index)}
              activeColors={activeColors}
              max={selectedArrangement}
              // unavailableColor={this.props.part.unavailableColor || []}
              unavailableColor={unavailableColor || []}
              hiddenColor={hiddenColor || []}
            />
            :
            <div className="color-picker__container">
              <img src="/img/loader.gif" alt="Loading Colors" className="color-picker-loader" />
            </div>
          }
        </div>
      </Box>
    );
  }
}
