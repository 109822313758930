import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./scrollView.style.css";

const ScrollView = ({ settings, children, activeIndex, heading }) => {
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const style = {
    fontSize: "11px",
    paddingLeft: "13px",
    fontWeight: 600,
    color: "#0a0a0a"
  };
   const style_red_text = {    
    fontSize: "11px",
    paddingLeft: "13px",
    fontWeight: 700,
    color:'#d90c0c'
      //color:'#6fb243'
    };

  return (
    <div>
      <div className="scroll-view__header">
        <h1 className="scroll-view__heading">{heading}</h1>

        <div className="scroll-view__controls">
          <span
            className="scroll-view__controls--button"
            style={{ opacity: activeIndex === 0 ? 0.4 : 1 }}
            onClick={previous}
          >
            <img src="/img/nav-prev.svg" alt="Previous" />
          </span>
          <span
            className="scroll-view__controls--button"
            style={{ opacity: activeIndex === children.length - 1 ? 0.4 : 1 }}
            onClick={next}
          >
            <img src="/img/nav-next.svg" alt="Next" />
          </span>
        </div>
      </div>

      {heading == "Give your trampoline a special name!" && (
        <p style={{ "padding-left": "15px" }}>
          Nametag ships separately, 3-4 weeks after trampoline.
        </p>
      )}
      {heading ==
        "This customizer tool is currently under maintenance and some features may not work. Please call our customer line @ 1800 586 772 or check back later. Sorry for the inconvenience." && (
        <div style={style}>
          <p>
            Colour customisation fee is <s>$199</s> $0 per customised trampoline. Delivery of colour customised trampolines will be up to
            four weeks from date of purchase.
          </p>
        </div>
      )}
      {heading == "Choose your trampoline model" && (
        <div style={style}>       
          <p>
            Colour customisation fee is <s>$199</s> $0 per customised trampoline. Delivery of colour customised trampolines will be up to
            four weeks from date of purchase.
          </p>
        </div>
      )}
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
    </div>
  );
};

export default ScrollView;
