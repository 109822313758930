import React, { Component } from 'react';
import ScrollView from '../../../ScrollView';
import { accessoryData } from './data';
import ModelCards from '../ModelCards';
import './accessories.style.css';
import { sliderSettings } from '../../../ScrollView/sliderSettings';
import { accessories as accessoryDataForSKU } from '../../../../helpers/trampoline-accessories';

export default class Accessories extends Component {
  state = {
    accessoryData,
    click: true,
    activeIndex: 0,
    activeVariant: '',
    statusResults: [
      { "code": 1, "message": "" }, 
      { "code": 2, "message": "No shipment date currently available. Call 1800 586 772 for more info." }
    ]
  };

  /**
   * select the accessories
   * and move the data to the parent data store
   */
  selectAccessories(selection) {
    if (!this.state.click || selection.name == 'FlexrStep') {
      return false;
    }
    let { selectedAccessories, updateSelectedAccessories } = this.props;
    if (selectedAccessories.includes(selection.id)) {
      const indexToRemove = selectedAccessories.indexOf(selection.id);
      selectedAccessories.splice(indexToRemove, 1);
    } else {
      selectedAccessories.push(selection.id);
    }
    console.log(selectedAccessories)
    updateSelectedAccessories(selectedAccessories);
  }

  selectVariant(variant,selection){

    let {activeVariants,updateSelectedVariants, selectedAccessories, updateSelectedAccessories} = this.props;
    activeVariants = [];
    activeVariants.push(variant);
    console.log(variant,selection);

    updateSelectedVariants(activeVariants);

    this.state.activeVariant = variant;
    if (selectedAccessories.includes(selection.id) && variant == '') {
      const indexToRemove = selectedAccessories.indexOf(selection.id);
      selectedAccessories.splice(indexToRemove, 1);
    } else if(!selectedAccessories.includes(selection.id)){
      selectedAccessories.push(selection.id);
    }

    updateSelectedAccessories(selectedAccessories);
  }


  render() {
    const { selectedAccessories, activeVariants, activeProductId, location, activeProductDetails } = this.props;
    const product = activeProductId;

    return (
      <div className="accessories__wrapper">        
        <ScrollView
          heading="Add accessories."
          settings={sliderSettings(this)}
          activeIndex={this.state.activeIndex}
        >
          {
            this.state.accessoryData.map((accessory, index) => {
              let accSkuId = accessoryDataForSKU[activeProductDetails['id']][accessory.id];

              if(accSkuId)
              {
                let accessoryStatusIndex = activeProductDetails['accessories'].findIndex(p => p.sku === accSkuId);

                if(accessoryStatusIndex >= 0)
                {
                  let accessoryStatus = activeProductDetails['accessories'][accessoryStatusIndex]['status'];
                  let variants = accessory[location].hasOwnProperty('variants')?accessory[location]['variants']:{};

                  accessory['status'] = accessoryStatus;


                  return (
                    <ModelCards
                      key={accessory.name}
                      cardIndex={index}
                      type="accessories"
                      price={accessory[location][product]}
                      activeIndex={selectedAccessories.includes(accessory.id) ? index : -1}
                      activeVariant={this.state.activeVariant}
                      selectIndex={() => this.selectAccessories(accessory)}
                      onVariantSelect={(variant,selection) => this.selectVariant(variant,accessory)}
                      accessoryStatus={accessoryStatus}
                      variants={variants}
                      {...accessory}
                    />
                  );
                }
              }
              return true;
            }
          )}
        </ScrollView>
      </div>
    );
  }
}
