export const accessories = {
  S155C: {
    flexHoop: "SP0079",
    flexrStep: "SP0080",
    sunshade: "SP0136",
    tgoma: "TGS155000",
    cover: "SP0129",
    groundAnchor: "JumboAnchorKit",
    shiftingWheels: "SP0122",
  },
  S113C: {
    flexHoop: "SP0079",
    flexrStep: "SP0080",
    sunshade: "SP0135",
    tgoma: "TGS113000",
    cover: "SP0128",
    groundAnchor: "SP0082",
    shiftingWheels: "SP0122",
  },
  O92C: {
    flexHoop: "SP0079",
    flexrStep: "SP0080",
    sunshade: "SP0134",
    // tgoma: "TGO92000",
    cover: "SP0127",
    groundAnchor: "SP0082",
    storageBag: "SP0137",
    carePack: "SFCPACK",
    shiftingWheels: "SP0122",
  },
  O77C: {
    flexHoop: "SP0079",
    flexrStep: "SP0080",
    sunshade: "SP0133",
    // tgoma: "TGO77000",
    cover: "SP0126",
    groundAnchor: "SP0082",
    storageBag: "SP0137",
    carePack: "SFCPACK",
    shiftingWheels: "SP0122",
  },
};
