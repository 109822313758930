export const uuids = {
  R79C: "0e375200-aafa-013b-5642-1ea3ddb62cf7",
  O47C: "86138bb0-ab00-013b-d63c-6206ce4f164f",
  R30C: "61351820-ab01-013b-9b23-728309690284",
  S155C: "94789fb0-aafb-013b-c591-262b82cdab0b",
  S113C: "bbf65f20-aafe-013b-dbc3-7233cc5908fd",
  O92C: "387004f0-aaff-013b-50b1-0efafa6ce6b7",
  O77C: "e3625920-ab01-013b-02d9-1ea3ddb62cf7"
};

export const variants = {};
